@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans-VariableFont_wdth,wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "PlusJakartaSans";
  src: url("./fonts/PlusJakartaSans-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/PlusJakartaSans-Italic-VariableFont_wght.ttf")
    format("truetype");
  font-style: italic;
}

html {
  scroll-behavior: smooth;
}
